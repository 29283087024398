<template>
  <div>
    <dialog-edit :value="value" @input="$emit('input')">
      <template v-slot:title
        >{{ id ? data.address : "Новый объект" }}
        <small v-if="id"> ({{ id }})</small>
      </template>
      <v-row>
        <v-col cols="12">
          <a-form-model
            v-model="data"
            :model="[model[0]]"
            :errors="errors"
            @validate="validate($event)"
          />
        </v-col>
        <v-col cols="12" sm="6">
          <a-form-model
            v-model="data"
            :model="[model[1], model[4], model[11], model[2], model[3]]"
            :errors="errors"
            @validate="validate($event)"
          />
        </v-col>
        <v-col cols="12" sm="6">
          <a-form-model
            v-model="data"
            :model="[model[6], model[5], model[8], model[10], model[12]]"
            :errors="errors"
            @validate="validate($event)"
          />
        </v-col>
        <v-col cols="12">
          <a-form-model
            v-model="data"
            :model="[model[7], model[9]]"
            :errors="errors"
            @validate="validate($event)"
          />
        </v-col>
      </v-row>
      <v-row> </v-row>
      <template v-slot:actions>
        <v-spacer></v-spacer>
        <v-btn @click="submit()" color="primary">Записать</v-btn>
        <v-btn v-if="id" @click="removeDialogShow = true" color="error"
          >Удалить</v-btn
        >
        <v-btn @click="$emit('input')">Закрыть</v-btn>
      </template>
    </dialog-edit>
    <remove-dialog v-model="removeDialogShow" @remove="remove(api, id)" />
  </div>
</template>

<script>
import { getForm, submitForm, removeEl } from "../../components/mixings";

export default {
  mixins: [getForm, submitForm, removeEl],
  props: {
    value: Boolean,
    id: Number,
    api: String,
    defaults: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      model: this.$store.getters["config/get"].models.objects.form,
      removeDialogShow: false,
    };
  },
  computed: {},
  watch: {
    value() {
      if (this.value) this.updateData(this.id);
    },
  },
  methods: {
    afterRemove(data) {
      this.removeDialogShow = false;
      this.$emit("input");
      this.$router.push({name: "objects"});
    },
    afterSave(data, status) {
      if (status) this.$emit("input");
      // this.$router.push({name: "objects_view", params: {id: this.id}});
    },
  },
};
</script>